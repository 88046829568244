import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';

import CampaignRoute from './CampaignRoute';

const CampaignPage = ({ location }) => {
  return (
    <>
      <Router basepath="/campaigns">
        <CampaignRoute path="/:slug" location={location} />
      </Router>
    </>
  );
};

CampaignPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default memo(CampaignPage);
